import React from "react";
import { Link } from "react-scroll";
import Logo from "../images/logos/logo.svg";

const Header = () => {
  const navigation = [
    { name: "About Us", href: "about-us" },
    { name: "Our Process", href: "our-process" },
  ];

  return (
    <header className="fadedown-animation">
      <div className="container mx-auto">
        <div className="flex py-5 items-center ">
          <div className="flex-grow basis-0 hidden md:flex"></div>
          <div className="md:flex flex-row gap-8 items-center flex-grow justify-center md:basis-auto basis-0">
            {navigation.map((item, idx) => (
              <>
                {idx === 1 ? (
                  <div
                    key={item.href}
                    className="flex flex-row gap-8 items-center"
                  >
                    <Link key={idx} to="/">
                      <img
                        key={item.href}
                        className="md:h-36 h-24 w-auto pointer-events-none select-none"
                        src={Logo}
                      />
                    </Link>
                  </div>
                ) : (
                  <></>
                )}
                <Link
                  to={item.href}
                  key={item.name}
                  smooth
                  className="md:text-body-lg text-body-sm font-medium text-neutral-700 cursor-pointer select-none hover:text-primary-600 px-4 hidden md:block"
                >
                  {item.name}
                </Link>
              </>
            ))}
          </div>
          <div className="flex flex-col gap-2 flex-grow md:basis-0 basis-7 ">
            <p className="text-body-sm font-light text-neutral-900 select-none">
              Contact us directly at
            </p>
            <a
              className="text-display-xs font-display font-normal text-primary-600 hover:underline decoration-2 underline-offset-4"
              href="tel:+12247951677"
            >
              +1 (224) 795-1677
            </a>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
