import React from "react";
import Divider from "../images/divider.svg";
import Ornament from "../images/ornament.svg";

const Footer = () => {
  // const data = useStaticQuery(graphql`
  //   {
  //     allSocialJson {
  //       nodes {
  //         id
  //         name
  //         href
  //         icon {
  //           publicURL
  //         }
  //       }
  //     }
  //   }
  // `);
  return (
    <footer id="contact-footer">
      <div className="container mx-auto">
        <div className="md:mt-20 md:pb-12 mt-12 pb-12 pointer-events-none">
          <img
            src={Divider}
            className="w-[100%] h-auto "
            alt="Artistic divider symbol"
          />
        </div>
        <div className="grid lg:grid-cols-12 grid-cols-1 lg:gap-8 gap-12">
          <div className="lg:col-span-6 md:pr-18 pr-16">
            <h3 className="font-display md:text-display-lg text-display-sm font-normal pb-4 text-neutral-900">
              Create your dream furniture with us
            </h3>
            <a
              href="mailto:andrzejslobrow@gmail.com?subject=Client%20Inquiry"
              className="font-display md:text-display-lg text-display-sm italic text-primary-600 hover:underline decoration-2 underline-offset-4"
            >
              Send us a message
            </a>
          </div>
          <div className="lg:col-span-6 flex flex-col gap-8 xl:pl-80 lg:pl-48">
            <div className="flex flex-col gap-2">
              <p className="text-display-xs font-display font-normal">
                Wheeling, Illinois
              </p>
              <p className="text-body-sm font-normal text-primary-600 hover:underline decoration-2 underline-offset-4">
                <a
                  href="https://maps.app.goo.gl/UpUT14rdAv7DRGgj9"
                  target="_blank"
                  rel="noreferrer"
                >
                  565 North Wolf Road, Wheeling, IL 60090, United States
                </a>
              </p>
            </div>
            <div className="flex flex-col gap-2">
              <p className="text-body-sm font-light text-neutral-900 ">
                Email us at
              </p>
              <a
                className="text-display-xs font-display font-normal text-primary-600 hover:underline decoration-2 underline-offset-4"
                href="mailto:andrzejslobrow@gmail.com?subject=Client%20Inquiry"
              >
                andrzejslobrow@gmail.com
              </a>
            </div>
            <div className="flex flex-col gap-2">
              <p className="text-body-sm font-light text-neutral-900">
                Or have a phone call with us
              </p>
              <a
                className="text-display-xs font-display font-normal text-primary-600 hover:underline decoration-2 underline-offset-4"
                href="tel:+12247951677"
              >
                +1 (224) 795-1677
              </a>
            </div>
          </div>
        </div>
        <div className="md:my-20 my-10" />
        <div className="flex lg:flex-row flex-col gap-8 lg:items-center justify-between md:mb-20 mb-10">
          <div className="text-body-md font-light" name="author">
            © {new Date().getFullYear()} Developed by{" "}
            <a
              href="https://martyka.net/?utm_source=slavoniccraft"
              className="text-primary-600"
            >
              martyka.net
            </a>
          </div>
          <div className="flex lg:flex-row flex-col lg:items-center md:gap-6 gap-4 self-center">
            <div className="flex flex-row items-center opacity-70">
              {/* <p className="text-body-sm font-semibold tracking-widest text-neutral-700 pr-4">
                CONNECT
              </p> */}
              <img src={Ornament} alt="Artistic ornament symbol" />
            </div>
            <div className="flex flex-row  items-center gap-6">
              {/* {data.allSocialJson.nodes.map((node) => (
                <a
                  href={node.href}
                  key={node.name}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="h-10 w-10"
                    src={node.icon.publicURL}
                    alt={node.name}
                  />
                </a>
              ))} */}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
